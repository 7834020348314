import { Routes, Route } from "react-router-dom";
import React, { createRef, useCallback, useEffect, useState } from "react";
import "./App.less";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Products from "./pages/products";
import About from "./pages/about";
import Contact from "./pages/contact";
import { MyContext } from "./MyContext";

function App() {
  const [device, setDevice] = useState<string>("pc");
  const dom = createRef<HTMLDivElement>();
  const handleWindowResize = useCallback(() => {
    if (!dom.current) return;
    if (dom.current.clientWidth >= 1080) {
      setDevice("pc");
    } else {
      setDevice("mobile");
    }
  }, [dom]);
  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  return (
    <div className="App" ref={dom}>
      <MyContext.Provider value={device}>
        <Header />
        <main className="page-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </MyContext.Provider>
    </div>
  );
}

export default App;
