import React from "react";
import "./index.less";
import { Steps } from "antd";

const About = () => {
  const items = [
    { title: "2006", description: "Was established in Shanghai." },
    {
      title: "2012",
      description: "Obtained import & export management license.",
    },
    {
      title: "2015",
      description: "Products transformation into middle- high level.",
    },
    {
      title: "2020",
      description:
        "Get a remarkable effect from Russian and Europen markets after transformation.",
    },
    {
      title: "2023",
      description: "We are in the fast growing-up time.",
    },
  ];

  return (
    <div className="about-page">
      <div className="about-conent">
        <div className="about-intro">
          <div className="about-intro-wrapper">
            <div className="about-img-bg">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/bg.png" alt="" />
            </div>
            <div className="description">
              <p className="paragraph">
                Shanghai Dehao was established in 2006, we are an international
                professional trade supplier of textiles and garments.
              </p>
              <p className="paragraph">
                Dehao head office locates in Shanghai, China. Our customers are
                mainly from Russia, Europe countries. We have many good
                factories in Zhejiang, Jiangsu, Anhui provinces, which can make
                cut-knit styles (T-shirt, sweatshirt, trousers), outerwear
                styles (down jacket, trench coat, light jacket) and woven styles
                from silk, linen materials.
              </p>
              <p className="paragraph">
                Our main products are in middle-high level end brands, such as
                12Storeez, IDOL from MFG, Swedish Fitness, PS of Sweden, and so
                on!
              </p>
              <p className="paragraph">
                We have good records, KPI on our quality and delivery in our all
                customers’ database. We have a professional and strong team to
                support us on whole procedures.
              </p>
            </div>
          </div>
          <div className="about-steps-wrapper">
            <div className="about-steps-title">
              The road from one store to an international fashion company
            </div>
            <Steps
              current={4}
              progressDot
              items={items}
              className="about-steps"
            />
          </div>
        </div>
      </div>
      <div className="about-makets">
        <div className="about-makets-title">OVERSEAS MAKETS</div>

        <div className="maket-map">
          <div className="maket-map-title">Product market map</div>
          <div className="maket-map-wrapper" style={{ flex: 1 }}>
            <div style={{ width: "54%" }}>
              <img
                src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/customer01.png"
                alt=""
                className="img"
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ width: "48%" }}>
              <img
                src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/customer02.png"
                alt=""
                className="img"
                style={{
                  width: "110%",
                  marginLeft: "-68px",
                  marginTop: "-10px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="maket-factories">
          <div className="maket-factories-title">Factories</div>
          <div className="maket-factories-wrapper">
            <img
              src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories01.png"
              alt=""
              className="img"
              style={{ width: "52%" }}
            />
            <img
              src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories02.png"
              alt=""
              className="img"
              style={{ width: "48%" }}
            />
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories03.png" alt="" className="img" />
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories04.png" alt="" className="img" />
          </div>
        </div>
      </div>
      <div className="about-supports">
        <div className="about-supports-title">Supports</div>
        <div className="about-supports-wrapper">
          <div className="supports-img-wrapper">
            <div className="supports-img supports-img1">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support01.png" alt="" style={{ width: "160px" }} />
            </div>
            <div className="supports-img supports-img2">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support02.png" alt="" />
            </div>
            <div className="supports-img supports-img3">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support03.png" alt="" style={{ width: "120px" }} />
            </div>
            <div className="supports-img supports-img4">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support04.png" alt="" style={{ width: "160px" }} />
            </div>
            <div className="supports-img supports-img5">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support05.png" alt="" style={{ width: "120px" }} />
            </div>
          </div>
          <div className="support-right">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
