import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.less";
import logo from "../../../assets/logo.png";
import { Menu } from "antd";

const PHeader: React.FC = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(
    location.pathname + location.search
  );
  const items = [
    { label: "Home", key: "/" },
    {
      label: "Products",
      key: "products",
      children: [
        { label: "CUT-KNIT", key: "/products?category=cutKnit" },
        { label: "Outerwear", key: "/products?category=outerwear" },
        {
          label: "Woven Series",
          key: "/products?category=wovenSeries",
        },
        { label: "Accessories", key: "/products?category=accessories" },
      ],
    },
    { label: "About DeHao", key: "/about" },
    { label: "Contact Us", key: "/contact" },
  ];

  const handleNav = (e: any) => {
    const { key } = e;
    setSelectedKey(key);
    navigate(key, { replace: false });
  };

  return (
    <header className="page-header">
      <div className="header-container">
        <div className="header-mid">
          <h1 className="logo-name" onClick={() => handleNav({ key: "/" })}>
            <img className="logo" src={logo} alt="logo" />
            <div className="name">
              <div className="cn-name">上海德豪服饰有限公司</div>
              <div className="en-name">
                Shanghai Dehao Import & Export Co., Ltd.
              </div>
            </div>
          </h1>

          <nav className="header-nav">
            <Menu
              items={items}
              mode="horizontal"
              defaultSelectedKeys={[location.pathname + location.search]}
              onClick={handleNav}
              selectedKeys={[selectedKey]}
            />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default PHeader;
