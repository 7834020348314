import React, { useContext } from "react";
import { MyContext } from "../../MyContext";
import MFooter from "./mobile";
import PFooter from "./pc";

const Footer = () => {
  const device = useContext(MyContext);
  return device === "pc" ? <PFooter /> : <MFooter />;
};

export default Footer;
