import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import logo from "../../../assets/logo.png";
import { Popup } from "antd-mobile";
import "./index.less";
import { useLocation, useNavigate } from "react-router-dom";
import { CgClose } from "react-icons/cg";
import { AiOutlineUp } from "react-icons/ai";

const MHeader = () => {
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const navigator = useNavigate();
  return (
    <header className="m-header-container">
      <div className="m-header-content">
        <div className="m-logo-content" onClick={() => navigator("/")}>
          <img src={logo} alt="logo" />
          <div className="name">
            <div className="cn-name">上海德豪服饰有限公司</div>
            <div className="en-name">
              Shanghai Dehao Import & Export Co., Ltd.
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setShowPanel(!showPanel);
          }}
        >
          <AiOutlineMenu style={{ fontSize: "2rem" }} />
        </div>
        <Popup visible={showPanel} position="right" bodyClassName="popup-body">
          {<Panel onClose={() => setShowPanel(false)} />}
        </Popup>
      </div>
    </header>
  );
};

const Panel: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [arrow, setArrow] = useState<boolean>(false);
  const navigate = useNavigate();
  const loaction = useLocation();
  const [addColor, setColor] = useState<boolean>(false) 
  const items = [
    // { label: "Home", key: "/" },
    {
      label: "Products",
      key: "products",
      children: [
        { label: "CUT-KNIT", key: "/products?category=cutKnit" },
        { label: "Outerwear", key: "/products?category=outerwear" },
        {
          label: "Woven Series",
          key: "/products?category=wovenSeries",
        },
        { label: "Accessories", key: "/products?category=accessories" },
      ],
    },
    { label: "About DeHao", key: "/about" },
    { label: "Contact Us", key: "/contact" },
  ];
  useEffect(() => {
    if(loaction.pathname === "/products") {
      setColor(true)
      setArrow(true)
    }else{
      setColor(false)
      setArrow(false)
    }
  }, [loaction.pathname])
  return (
    <>
      <div className="panel-close" onClick={() => onClose()}>
        <CgClose className="close-icon" />
      </div>
      <div className="panel-content">
        {items.map((menu) => {
          return (
            <div key={menu.label}>
              <div
                className="panel-item"
                onClick={() => {
                  menu.children && setArrow(!arrow);
                  if (!menu.children) {
                    navigate(menu.key);
                    onClose();
                  }
                }}
              >
                <div
                  className={`panel-label ${
                    !loaction.search && loaction.pathname === menu.key
                      ? "active-color"
                      : ""
                  } ${addColor && menu.key === 'products' ? 'active-color' : ''}
                  `}
                >
                  {menu.label}
                </div>
                {menu.children && (
                  <div
                    className={`panel-arrow  ${
                      arrow ? "panel-arrow-up" : "panel-arrow-down"
                    }`}
                  >
                    <AiOutlineUp  className={`${addColor ? 'active-color' : ''}`} />
                  </div>
                )}
              </div>
              {menu.children?.map((item) => {
                return (
                  <div
                    key={item.label}
                    className="panel-menu-container"
                    style={{
                      maxHeight: !arrow ? "0" : "20rem",
                    }}
                    onClick={() => {
                      navigate(item.key);
                      onClose();
                    }}
                  >
                    <div
                      className={`panel-menu-item ${
                        item.key.slice(item.key.search(/\?/g)) ===
                        loaction.search
                          ? "active-color"
                          : ""
                      }`}
                    >
                      {item.label}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MHeader;
