import React from "react";
import "./index.less";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="footer-mid">
        <div className="footer-row">
          <div className="tel-fax">
            <div className="tel">
              <div className="tel-title">Tel:</div>
              <div className="tel-content">(86-21)52856921</div>
            </div>
            <div className="fax">
              <div className="fax-title">Fax:</div>
              <div className="fax-content">(86-21)52859644</div>
            </div>
          </div>
          <div className="email">
            <div className="emai-title">Email:</div>
            <div className="email-list">
              <a href="mailto:jackchan@dehaogroup.com">
                jackchan@dehaogroup.com
              </a>
              <a href="mailto:maryxia@dehaogroup.com">maryxia@dehaogroup.com</a>
            </div>
          </div>
        </div>
        <div className="footer-row">
          <div className="address">
            4th Building Fashion World Plaza,Lane 5000 Gonghexin
            Road,Shanghai,China. Zip: 200435
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
