import React, { useContext } from "react";
import { MyContext } from "../../MyContext";
import MobileContact from "./mobile";
import PcContact from "./pc";

const Contact = () => {
  const device = useContext(MyContext);
  return device === "pc" ? <PcContact /> : <MobileContact />;
};

export default Contact;
