import React, { useEffect } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";

import "@amap/amap-jsapi-types";

const position: [number, number] = [121.443885,31.322099];
const mapOptions: AMap.MapOptions = {
  viewMode: "3D", // 是否为3D地图模式
  center: position, // 初始化地图中心点位置
  zoom: 17, // 初始化地图级别
};

const Map = () => {
  let map: any;
  useEffect(() => {
    AMapLoader.load({
      key: "b3aaf1cfc297f3dec28d0c8c2d7698ba", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
      .then((AMap) => {
        map = new AMap.Map("container", mapOptions);
        let marker = new AMap.Marker({
          position, // 基点位置
        });
        // 地图添加标记
        map.add(marker);
        let infoWindow = new AMap.InfoWindow({
          anchor: "top-left",
          content: `<p style= "width: 16rem">Room 1311-1313,4th Building Fashion World Plaza, Lane 5000, Gonghexin Road, Shanghai,China</p>`,
        });
        infoWindow.open(map, position);
        marker.on("click", function () {
          infoWindow.open(map);
        });
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      if (map) map.destroy();
    };
  }, []);
  return <div id="container" style={{ height: "30rem", width: "100%" }}></div>;
};

export default Map;
