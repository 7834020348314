import React from "react";
import "./index.less";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { LiaFaxSolid } from "react-icons/lia";

const MFooter = () => {
  return (
    <div className="m-footer-container">
      <div className="m-tel-content">
        <div className="m-title">
          <BsTelephone className="m-icon" />
          <span>Tel:</span>
        </div>
        <div className="m-content">(86-21)52856921</div>
      </div>
      <div className="m-tel-content">
        <div className="m-title">
          <LiaFaxSolid className="m-icon" />
          <span>Fax:</span>
        </div>
        <div>(86-21)52859644</div>
      </div>
      <div className="m-tel-content">
        <div className="m-title">
          <AiOutlineMail className="m-icon" />
          <span>Email:</span>
        </div>
        <div>jackchan@dehaogroup.com</div>
      </div>
    </div>
  );
};

export default MFooter;
