import React, { useContext } from "react";
import { MyContext } from "../../MyContext";
import PcHome from "./pc";
import MobileHome from "./mobile";

const Home = () => {
  const devcie = useContext(MyContext);
  return devcie === "pc" ? <PcHome /> : <MobileHome />;
};

export default Home;
