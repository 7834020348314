import React, { useEffect, useState } from "react";
import "./index.less";
import { useSearchParams } from "react-router-dom";

const PcProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(searchParams.get("category"));
  // const [wrapper, setWrapper] = useState<ChildernTypes | null>();
  // const handleMouseEnter = (index: number) => {
  //   const arr = wrapper && [...wrapper];
  //   arr?.forEach((it, Index) => {
  //     if (index === Index) {
  //       it.mask = false;
  //     } else {
  //       it.mask = true;
  //     }
  //   });
  //   setWrapper(arr);
  // };
  // const handleMouseLeave = () => {
  //   const arr = wrapper && {...wrapper};
  //   arr?.children?.forEach((it) => {
  //     it.mask = true;
  //   });
  //   setWrapper(arr);
  // };
  useEffect(() => {
    // const filterArr = items.filter(
    //   (item) => item.category === searchParams.get("category")
    // );
    // console.log("object", filterArr);
    // setWrapper(filterArr[0]);
    setParams(searchParams.get("category"));
  }, [searchParams.get("category")]);
  return (
    <div className="container products-page">
      {/* {wrapper?.children?.map((a, index) => {
          return (
            <div
              key={index}
              // className={`product-item ${hover}? product-itembg : ''`}
              className={[
                "product-item",
                a.mask ? "product-itembg" : null,
              ].join(" ")}
              style={{
                width: `${
                  wrapper.category === "accessories" && index === 4
                    ? "700px"
                    : wrapper.category === "accessories" && index === 5
                    ? "460px"
                    : wrapper.category === "accessories"
                    ? "260px"
                    : ""
                }`,
              }}
              // onMouseEnter={() => handleMouseEnter(index)}
              // onMouseLeave={handleMouseLeave}
            >
              <img
                src={a.img}
                alt=""
                className={`${
                  index === 1 && wrapper.category === "cutKnit"
                    ? "cutKnit-img02"
                    : ""
                }  ${
                  index === 3 && wrapper.category === "cutKnit"
                    ? "cutKnit-img04"
                    : ""
                }`}
              />
              {a.mask && <div>{a.label}</div>}
            </div>
          );
        })} */}
      {params === "cutKnit" && (
        <div className="img-wrapper">
          <div className="product-item">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit05.png" alt="" />
          </div>
          <div className="cuknit-center">
            <div className="product-item1" style={{ width: "207px" }}>
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit07.png" alt="" />
            </div>
            <div className="product-item1" style={{ width: "211px" }}>
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit08.png" alt="" className="cutKnit-img02" />
            </div>
            <div className="product-item1" style={{ width: "207px" }}>
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit09.png" alt="" />
            </div>
            <div className="product-item1" style={{ width: "211px" }}>
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit10.png" alt="" className="cutKnit-img04" />
            </div>
          </div>
          <div className="product-item">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit06.png" alt="" />
          </div>
        </div>
      )}
      {params === "outerwear" && (
        <div className="img-box">
          <div className="outerwear-wrapper">
            <div style={{ width: "320px" }}>
              <img
                src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/outerwear03.png"
                alt=""
                style={{ width: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="product-item" style={{ width: "437px" }}>
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/outerwear01.png" alt="" />
            </div>
            {/* <div className="product-item" style={{ width: "722px" }}>
              <img src={outerwear02} alt="" />
            </div> */}
            <div className="product-item" style={{ width: "444px" }}>
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/outerwear04.png" alt="" />
            </div>
          </div>
        </div>
      )}
      {params === "wovenSeries" && (
        <div className="wovenseries-wrapper">
          <div className="product-wovenseries" style={{ width: "488px" }}>
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries01.png" alt="" />
          </div>
          <div className="product-wovenseries" style={{ width: "630px" }}>
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries02.png" alt="" />
          </div>
          <div className="product-wovenseries" style={{ width: "555px" }}>
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries03.png" alt="" />
          </div>
          <div className="product-wovenseries" style={{ width: "560px" }}>
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries05.png" alt="" />
          </div>
        </div>
      )}
      {params === "accessories" && (
        <div className="img-wrapper">
          <div className="accessories">
            <div className="product-accessories">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories09.png" alt="" />
            </div>
            <div className="product-accessories">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories08.png" alt="" />
            </div>
            <div className="product-accessories">
              <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories07.png" alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PcProducts;
