import React, { useContext } from "react";
import { MyContext } from "../../MyContext";
import MProducts from "./mobile";
import PcProducts from "./pc";

const Products = () => {
  const device = useContext(MyContext);
  return device === "pc" ? <PcProducts /> : <MProducts />;
};

export default Products;
