import React from "react";
import "./index.less";
import { Steps } from "antd-mobile";
import { Step } from "antd-mobile/es/components/steps/step";

type Items = {
  title: string;
  description: string;
};

const MobileAbout = () => {
  const items: Items[] = [
    { title: "2006", description: "Was established in Shanghai." },
    {
      title: "2012",
      description: "Obtained import & export management license.",
    },
    {
      title: "2015",
      description: "Products transformation into middle- high level.",
    },
    {
      title: "2020",
      description:
        "Get a remarkable effect from Russian and Europen markets after transformation.",
    },
    {
      title: "2023",
      description: "We are in the fast growing-up time.",
    },
  ];
  return (
    <div className="m-about-conatiner">
      <div className="m-img">
        <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/bg.png" alt="" />
      </div>
      <div className="m-content">
        <h1>About DeHao</h1>
        <p className="m-paragraph">
          Shanghai Dehao was established in 2006, we are an international
          professional trade supplier of textiles and garments.
        </p>
        <p className="m-paragraph">
          Dehao head office locates in Shanghai, China. Our customers are mainly
          from Russia, Europe countries. We have many good factories in
          Zhejiang, Jiangsu, Anhui provinces, which can make cut-knit styles
          (T-shirt, sweatshirt, trousers), outerwear styles (down jacket, trench
          coat, light jacket) and woven styles from silk, linen materials.
        </p>
        <p className="m-paragraph">
          Our main products are in middle-high level end brands, such as
          12Storeez, IDOL from MFG, Swedish Fitness, PS of Sweden, and so on!
        </p>
        <p className="m-paragraph">
          We have good records, KPI on our quality and delivery in our all
          customers’ database. We have a professional and strong team to support
          us on whole procedures.
        </p>
        <div className="company-line">
          <div className="makets-title">Company development line</div>
          <Steps current={4} direction="vertical">
            {items.map((item) => {
              return (
                <Step
                  title={item.title}
                  description={item.description}
                  key={item.title}
                />
              );
            })}
          </Steps>
        </div>
        <h1>Overseas Makets</h1>
        <div>
          <div className="makets-title">Product market map</div>
          <div className="about-img-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/customer01.png" alt="" />
          </div>
          <div className="about-img-box">
            <img
              src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/customer02.png"
              alt=""
              style={{ marginTop: "2.8rem", marginLeft: "-3rem" }}
            />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <div className="makets-title">Factories</div>
          <div className="about-img-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories01.png" alt="" />
          </div>
          <div className="about-img-box">
            <img
              src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories02.png"
              alt=""
              style={{ marginTop: "-2rem", marginLeft: "-2.8rem" }}
            />
          </div>
          <div className="about-img-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories03.png" alt="" style={{ marginTop: "2.6rem" }} />
          </div>
          <div className="about-img-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/factories04.png" alt="" style={{ marginTop: "2.6rem" }} />
          </div>
        </div>
        <h1>Supports</h1>
        <div className="support-box">
          <div className="supprt-img-wrapper">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support01.png" alt="" style={{ width: "80%" }} />
          </div>
          <div className="supprt-img-wrapper">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support03.png" alt="" style={{ width: "80%" }} />
          </div>
          <div className="supprt-img-wrapper">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support02.png" alt="" />
          </div>
          <div className="supprt-img-wrapper">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support04.png" alt="" style={{ width: "88%" }}/>
          </div>
          <div className="supprt-img-wrapper">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/support05.png" alt="" style={{ width: "80%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAbout;
