import React, { useContext } from "react";
import { MyContext } from "../../MyContext";
import PcAbout from "./pc";
import MobileAbout from "./mobile";

const About = () => {
  const devcie = useContext(MyContext);
  return devcie === "pc" ? <PcAbout /> : <MobileAbout />;
};

export default About;
