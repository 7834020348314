import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./index.less";

const MProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentParams, setCurrentParams] = useState(
    searchParams.get("category")
  );
  useEffect(() => {
    setCurrentParams(searchParams.get("category"));
  }, [searchParams.get("category")]);
  return (
    <div>
      {currentParams === "cutKnit" && (
        <div className="cutknit-wrapper">
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit05.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit07.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit08.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit10.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit09.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cutknit06.png" alt="" />
          </div>
        </div>
      )}
      {currentParams === "outerwear" && (
        <div className="cutknit-wrapper">
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/outerwear03.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/outerwear01.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/outerwear04.png" alt="" />
          </div>
        </div>
      )}
      {currentParams === "wovenSeries" && (
        <div className="cutknit-wrapper">
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries01.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries02.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries03.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/wovenseries05.png" alt="" />
          </div>
        </div>
      )}
      {currentParams === "accessories" && (
        <div className="cutknit-wrapper">
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories09.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/Accessories03.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories05.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories06.png" alt="" />
          </div>
          <div className="cutknit-box">
            <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/accessories07.png" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MProducts;
