import React, { useContext } from "react";
import MHeader from "./mobile";
import PHeader from "./pc";
import { MyContext } from "../../MyContext";

const Header = () => {
  const device = useContext(MyContext);
  return device === "pc" ? <PHeader /> : <MHeader />;
};

export default Header;
