import React from "react";
import "./index.less";
import MapContainer from "../../../components/mapContainer/pc";

const Contact = () => {
  return (
    <div className="container contact-page">
      <MapContainer />
      <div className="content">
        <div className="content-left">
          <div className="phone-content">
            <div className="msg-title">Tel:</div>
            <div>(86)21-56538811</div>
          </div>
          <div className="phone-content">
            <div className="msg-title">Mob:</div>
            <div>+86 13816877597</div>
          </div>
          <div className="phone-content">
            <div className="msg-title">Web: </div>
            <a href="https://www.dehaogroup.com">https://www.dehaogroup.com</a>
          </div>
          <div className="phone-content">
            <div className="msg-title">Email: </div>
            <div className="list-email">
              <a href="mailto:jackchan@dehaogroup.com">
                jackchan@dehaogroup.com
              </a>
              <a href="mailto:maryxia@dehaogroup.com">maryxia@dehaogroup.com</a>
            </div>
          </div>
          <div className="phone-content">
            <div className="msg-title">Wechat: </div>
            <div>maryxia1202</div>
          </div>
        </div>
        <div className="qr-code">
          <img src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/qr-code.png" alt="" />
          <div>Scan for more information</div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
