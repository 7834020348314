import React from "react";
import { Swiper } from "antd-mobile";
import "./index.less";

const MobileHone = () => {
  return (
    <div>
      <Swiper
        loop
        autoplay
        indicatorProps={{ style: { "--active-dot-color": "#FA1011" } }}
      >
        <Swiper.Item>
          <div className="carousel-item">
            <img className="carousel-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/banner2.webp" alt="" />
            <div className="carousel-text">
              A Professional International Supplier of Textiles And Garments
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="carousel-item">
            <img className="carousel-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/banner1.webp" alt="" />
            <div className="carousel-text">
              <p className="carousel-text-title">Our Business Philosophy:</p>
              <p className="carousel-text-p1">First Customer</p>
              <p className="carousel-text-p2">First Quality</p>
              <p className="carousel-text-p3">First Service</p>
              <p className="carousel-text-p4">First Staff</p>
            </div>
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="carousel-item">
            <img className="carousel-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/banner3.webp" alt="" />
            <div className="carousel-text">
              Looking Forward To Cooperating With You
            </div>
          </div>
        </Swiper.Item>
      </Swiper>
      <div className="group-info">
        <div className="group-title">DEHAO GROUP</div>
        <div className="group-box">
          <img className="group-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/bgk.jpg" alt="" />
          <div className="group-info-content">
            <p className="group-info-content-p">
              Shanghai Dehao Import & Export Co., LTD is a professional
              international supplier of textiles and garments.
            </p>
            <p className="group-info-content-p">
              We have a group of experienced staff who are engaged in import &
              export of all kinds of weave and knitting garments. Since the
              establishment of Dehao apparel Co., Ltd, we have been doing our
              best to improve all kinds of systems and to introduce the talented
              staff so as to comply with market development. We also try to
              probe the mode of trading and manufacturing as well. So far, in
              addition to our factories, we also have some production factories
              that cooperate long-term with us in Shanghai, Zhejiang , Anhui ,
              and Jiangsu Province, which are able to manufacture the weaving
              products such as jackets, shirts, windbreaker, casual pants, down
              wear, skirts, and the knitting products such as T-shirts, sports
              wear, garments branded with Polo and etc. Our products are mainly
              exported to Europe, the United States, Russia, Korea, Japan and
              other countries.
            </p>
            <p className="group-info-content-p">
              In order to provide goods with competitive price to our customers,
              taking fully advantage of being familiar with domestic market, we
              carefully choose the most appropriate raw material suppliers and
              product manufacturers. For the sake of insuring the goods quality
              offered by us, in the whole link from raw material purchase to
              product manufacture, we all arrange the professional personnel to
              be responsible and to supervise. With focusing on the request of
              our customers, we make response as soon as possible to all
              instruction of them. Our goal is to provide the best service to
              our customers.
            </p>
            <p className="group-info-content-p margin-bottom-0">
              The customer’s smile is our biggest repayment. We sincerely
              welcome the old and new friends to visit our company and to
              cooperate with us.
            </p>
          </div>
        </div>
      </div>
      <div className="our-products">
        <div className="our-products-title">OUR PRODUCTS</div>
        <div className="products-content">
          <div className="products-content-item">
            <img className="product-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cloth7.webp" alt="" />
            <div className="product-info">Dress</div>
          </div>
          <div className="products-content-item">
            <img className="product-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cloth8.png" alt="" />
            <div className="product-info">Jackets</div>
          </div>
          <div className="products-content-item">
            <img className="product-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cloth4.webp" alt="" />
            <div className="product-info">Sportswear</div>
          </div>
          <div className="products-content-item">
            <img className="product-img" src="https://dehao-web-public.oss-cn-shanghai.aliyuncs.com/cloth2.webp" alt="" />
            <div className="product-info">Sweater</div>
          </div>
        </div>
      </div>
      <div className="our-partner">
        <div className="our-partner-title">OUR PARTNERS</div>

        <div className="partner-content">
          {["adidas", "columbia", "fila", "nike", "puma"].map((item) => (
            <div className={`partner-item ${item}`} key={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileHone;
